import ParseContent from 'components/shared/ParseContent'
import React from 'react'

// Third Party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface WorkmethodStepsProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const Content = styled(ParseContent)`
  & p {
    line-height: 30px;
  }

  @media (max-width: 575px) {
    & br {
      display: none;
    }
  }
`

const StyledWorkmethodSteps = styled.section`
  .row {
    padding-bottom: 180px;

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  @media (max-width: 991px) {
    .row {
      padding-bottom: 120px;
    }
  }
`

const Tetris = styled(Plaatjie)`
  position: relative;
  z-index: 2;

  @media screen and (max-width: 1199px) {
    width: 100%;
  }

  @media (max-width: 991px) {
    max-width: 400px;
  }
`

const TetrisShadowLeft = styled(Plaatjie)`
  position: absolute !important;
  top: -60px;
  left: -100px;
  z-index: 1;

  @media (max-width: 1199px) {
    width: 100%;
  }

  @media (max-width: 991px) {
    max-width: 400px;
    left: 40px;
    top: -40px;
  }
`

const TetrisShadowRight = styled(Plaatjie)`
  position: absolute !important;
  top: -40px;
  right: -140px;
  z-index: 1;

  @media (max-width: 1199px) {
    width: 100%;
  }

  @media (max-width: 991px) {
    max-width: 400px;
    right: 40px;
    top: -30px;
  }
`

const TopImage = styled(Plaatjie)`
  position: absolute !important;
  z-index: 3;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 340px;
`

const Number = styled.div`
  padding-top: 14px;
  font-size: 100px;
  line-height: 40px;
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.font.weight.bold};

  @media (max-width: 575px) {
    font-size: 60px;
  }
`

const WorkmethodSteps: React.FC<WorkmethodStepsProps> = ({ fields }) => (
  <StyledWorkmethodSteps>
    <div className="container">
      {fields.steps?.map((step, index) => (
        <>
          {index % 2 === 0 ? (
            <div className="row g-lg-4 g-3" key={index}>
              <div className="col-lg-4 d-flex justify-content-center position-relative">
                <TetrisShadowLeft
                  image={step?.tetrisshadow}
                  alt="tetris-blok-schaduw"
                />
                <Tetris image={step?.tetris} alt="tetris-blok" />
                <TopImage image={step?.image} alt="top-afbeelding" />
              </div>
              <div className="col-xl-1 col-lg-2 col-2 pt-lg-0 pt-md-5 pt-4 d-flex justify-content-end">
                <Number>{`${index + 1}.`}</Number>
              </div>
              <div className="col-lg-6 col-md-9 col-10 pt-lg-0 pt-md-5 pt-4">
                <Content content={step?.description || ''} />
              </div>
            </div>
          ) : (
            <div className="row g-lg-4 g-3" key={index}>
              <div className="col-lg-4 d-lg-none d-flex justify-content-center position-relative">
                <TetrisShadowRight
                  image={step?.tetrisshadow}
                  alt="tetris-blok-schaduw"
                />
                <Tetris image={step?.tetris} alt="tetris-blok" />
                <TopImage
                  image={step?.image}
                  alt="top-afbeelding"
                  objectFit="contain"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-2 d-flex justify-content-end pt-lg-0 pt-md-5 pt-4">
                <Number>{`${index + 1}.`}</Number>
              </div>
              <div className="col-lg-5 col-md-9 col-10 pt-lg-0 pt-md-5 pt-4">
                <Content content={step?.description || ''} />
              </div>
              <div className="col-xl-2 col-lg-1" />
              <div className="col-lg-4 d-lg-flex d-none justify-content-center position-relative">
                <TetrisShadowRight
                  image={step?.tetrisshadow}
                  alt="tetris-blok-schaduw"
                />
                <Tetris image={step?.tetris} alt="tetris-blok" />
                <TopImage image={step?.image} alt="top-afbeelding" />
              </div>
            </div>
          )}
        </>
      ))}
    </div>
  </StyledWorkmethodSteps>
)

export default WorkmethodSteps
